import ASCII from "./ASCII";
export default {
    getASCII(data) {
        return MD5(ASCII.sort_ascii(data)).toString().toUpperCase();
    },
    //ASCII加密
    setASCII(data) {
        return ASCII.sort_ascii(data);
    }
}
