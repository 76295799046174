
import CryptoJS from 'crypto-js';

export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },

    //加密
    encrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : 'E3XowIkyA9nmuSou'; //判断是否存在ksy，不存在就用定义好的key
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var str = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(str,key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
        return  encryptedBase64Data//encrypted.toString();
    },
    //解密
    decrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : 'E3XowIkyA9nmuSou';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }

}
// export default {
//     // 加密函數   string要加解密的字符串   code加解密的秘钥字符串（自己定义保持与PHP端一致）  operation为true的时候代表解密
//     secret(string) {
//         var key  = CryptoJS.enc.Utf8.parse('m3f12dcuzsoo3ye3');
//             string = JSON.stringify(string);
//             // 加密
//             return CryptoJS.AES.encrypt(string,key, { iv:'',mode:CryptoJS.mode.ECB, padding:CryptoJS.pad.Pkcs7 }).toString();
//     }

// }
// export default {
//     secret(str) {
//         console.log(str)
//         var AesKey = 'm3f12dcuzsoo3ye3';//加密时用的key,跟php一样

//         var message = str;//加密后的字符窜

//         var ECBOptions = {

//             mode: CryptoJS.mode.ECB,
          
//             padding: CryptoJS.pad.Pkcs7

//         };

//         var key = CryptoJS.enc.Utf8.parse(AesKey);

//         var bytes = CryptoJS.AES.encrypt(message, key, ECBOptions);
//         console.log(bytes.toString())

//         var originalText = bytes.toString(CryptoJS.enc.Utf8);
//         return originalText
//     }

// }